import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import {
  Observable,
} from 'rxjs';

import {
  environment
} from 'src/environments/environment';

interface TokenResponse {
  "token_type": string,
  "access_token": string,
  "expires_in": number
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  options = {
    headers: this.headers,
  }
  public myAuth:any;
  public isAuthenticated:any;
  public redirectFlag: any;
  constructor(private httpClient: HttpClient) {}

  login(input: any): Observable < TokenResponse > {
    const body = `username=${input.username.trim().toLowerCase()}&` +
      `password=${input.password}&` +
      `grant_type=password&` +
      `client_id=${input.client_id}&` +
      `client_secret=${input.client_secret}`;
    return this.httpClient.post < TokenResponse > (environment.authServiceURL, body, this.options)
  }
}
