import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedInfoService {

  public detailPageData = {
    breadcrumbs:{},
    heading:"",
    heading_content:"",
    demo_url:"",
    subheading:"",
    image_path:"",
    solution_desc:""
  };

  public briefingData={
    breadcrumbs:{},
  }
  constructor() { }
}
