import { Component, OnInit } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedInfoService } from 'src/app/services/shared-info.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-generative-ai',
  templateUrl: './generative-ai.component.html',
  styleUrls: ['./generative-ai.component.scss']
})
export class GenerativeAiComponent implements OnInit {
  selectedImage:any;
  showModal = false;
  heading: any;
  heading_content: any;
  detail_data: any;
  breadcrumbs: any
  temp: any;
  demo_url: any;
  image_path: any;
  constructor(public sharedInfoService: SharedInfoService,
    public oidcSecurityService: OidcSecurityService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private loginService: LoginService,
    public router: Router,
    private localStorage: LocalStorageService,) { }

  ngOnInit(): void {
    // this.heading = "OT Solutions";
    // this.heading_content = "Boost employee engagement and productivity, Provide a unified experience across IT, HR, Facilities, Legal, and more. Make it easy for employees to navigate complex processes and get the information they need in their preferred channels.";
    //console.log("detailPageData--", this.sharedInfoService.detailPageData)
    this.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/generativeai'
      }
    ];
    this.sharedInfoService.briefingData.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/generativeai'
      },
      {
        label: 'Schedule Briefing',
        url: '/briefing'
      }
    ];
    this.image_path = this.sharedInfoService.detailPageData.image_path;
    if (this.sharedInfoService.detailPageData.heading == "") {
      //this.router.navigate(['/landing_page']);
    }

  }

  demo() {
    if (this.sharedInfoService.detailPageData.demo_url !== "" || this.sharedInfoService.detailPageData.demo_url == undefined) {
      console.log("Demo###", this.sharedInfoService.detailPageData.heading)
      if (this.sharedInfoService.detailPageData.heading === 'Generative AI Services') {
        this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
          //console.log("i am here......................",loginResponse)
          const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
          this.loginService.isAuthenticated = isAuthenticated;
          if (isAuthenticated) {
            this.localStorage.store('redirectFlag', 0);
            window.open(this.sharedInfoService.detailPageData.demo_url);
            // this.demo_url = this.sharedInfoService.detailPageData.demo_url;
          }
          else {
            if (this.sharedInfoService.detailPageData.heading === 'Generative AI Services') {
              this.localStorage.store('redirectFlag', 2);
            }
            else if (this.sharedInfoService.detailPageData.heading === 'HR Service Delivery') {
              this.localStorage.store('redirectFlag', 4);
            }
            this.router.navigate(['/login']);
          }
        });
      } else {
        //this.demo_url = this.sharedInfoService.detailPageData.demo_url;
        this.localStorage.store('redirectFlag', 0);
        window.open(this.sharedInfoService.detailPageData.demo_url);
      }
    }
  }

  briefing() {
    this.router.navigate(['/briefing']);
  }

  openModal() {
    console.log("i ma in mode")
    this.selectedImage = "../../../assets/images/generative_ai/GenerativeAI.png";
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.selectedImage = "";  
  }
}
