import { Injectable,Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
    public oidcSecurityService: OidcSecurityService, private router: Router,) { }
        intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthHeaderToAllowedOrigins(request));
     }

     private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
     let req = request;
     //const allowedOrigins = ['http://localhost'];
     //if (!!allowedOrigins.find(origin => request.url.includes(origin))) {
    //  const authToken = this.oidcSecurityService.getAccessToken();
    //  req = request.clone({ setHeaders: { 'Authorization': `Bearer ${authToken}` } });
    //  if (authToken != null) {

    //      this.router.navigate(['/landing_page']);
        
    //      }
     // }

     return req;
}
 // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 //     // add auth header with jwt if user is logged in and request is to the api url
//    // const currentUser = this.authenticationService.currentUserValue;
//    //console.log("this.authenticationService",this.authenticationService)
//   // console.log("this.isLoggednIn",this.authenticationService.isLoggednIn())
 //  //   const isLoggedIn = currentUser && currentUser.token;
//    // const isApiUrl = request.url.startsWith(environment.apiUrl);
//    // if (this.authenticationService.isLoggednIn() && isApiUrl) {
     //     //console.log("this.authenticationService.isLoggednIn()",this.authenticationService)
//        console.log("this.authenticationService.isLoggednIn()",this.authenticationService.isLoggednIn())
//         if (this.authenticationService.isLoggednIn() ) {
//         request = request.clone({
//             setHeaders: {
//                 Authorization: `Bearer ${this.authenticationService.getToken()}`,
//                 // 'Access-Control-Allow-Origin': '*',
//                 // 'Content-Type': 'application/json',
//             }
//         });
//         //console.log("JwtInterceptor",`Bearer ${this.authenticationService.getToken()}`)
 //     }

 //     return next.handle(request);
 // }
}
