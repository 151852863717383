<body>
    <app-header></app-header>
    <div class="parent">
<div class="container-fluid">
    <div class="container top_div">
        <div class="breadcrumbs-div">
            <app-breadcrumbs [breadcrumbs]="this.breadcrumbs"> </app-breadcrumbs>
        </div>

    </div>
    
</div>
    <div class="container bottom_div">
        <div class=" heading-details">
            <h3 class="slider_heading">Schedule a Briefing</h3>
        </div>
        <div class="content_div">
        <div class="content">
            <p>Schedule a briefing today to see how Alice Dx Generative AI service can power your enterprise systems, get answers to your unique questions, and find out why Alice Dx Generative AI Service is the right choice for you</p>
        </div>
        <div class="form_div">
            <form [formGroup]="briefingPersonalInputForm">
                <div class = "formPersonal-row">
                 <div class = "row first-row-personal-form">
                <div class = "col-sm-6">
                <label class="margin-left label_font">First Name</label>
                </div>
                <div class = "col-sm-6">
                <label class=" margin-col label_font">Last Name</label></div>
  
                <div class = "col-sm-6">
                    
                    <input type="text" id="name" name="name" formControlName="name"  required minlength="1" maxlength="250"  class="margin-left input-size form-control form-control-sm" pattern="[a-zA-Z]+" [class.is-invalid]="name?.invalid && (name.dirty || name.touched)">
                   <div class = "error-content-1 extra_text"
                   >
                    &nbsp; 
                   </div>
                    <div *ngIf="name?.invalid && (name.dirty || name.touched)" class="invalid-feedback error-content-1">
                      <!-- <div *ngIf="name.errors?.['required']">
                      
                      </div>
                      <div *ngIf="name.errors?.['pattern']">
                      
                </div>
                      <div *ngIf="name.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div *ngIf="name.errors?.['maxlength']">
                        This field must have at most 250 characters.
                      </div> -->
                    </div>
                </div>
                <div class = "col-sm-6">
                    <input type="text" id="lastName" name="lastName" formControlName="lastName"  required minlength="1" maxlength="250"  class="input-size margin-col form-control form-control-sm" pattern="[a-zA-Z]+" [class.is-invalid]="lastName?.invalid && (lastName.dirty || lastName.touched)">
                    <div class = "error-content-2 extra_text"  
                      >
                      &nbsp; 
                     </div>
                    <div *ngIf="lastName?.invalid && (lastName.dirty || lastName.touched)" class="invalid-feedback error-content-2">
                      <!-- <div *ngIf="lastName.errors?.['required']">
                        
                      </div>
                      <div *ngIf="lastName.errors?.['pattern']">
                        
                </div>
                      <div *ngIf="lastName.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div *ngIf="lastName.errors?.['maxlength']">
                        This field must have at most 250 characters.
                      </div> -->
                    </div>
                </div>
             </div>

             <div class = "row margin-top">
                <div class = "col-sm-6">
                <label class="margin-left vehicleClassType-label label_font"> Work Email</label>
                </div>
                <div class = "col-sm-6">
                </div>
  
                <div class = "col-sm-6">
                    
                    <input type="email" id="email" name="email" formControlName="email"  required minlength="1" maxlength="250" class="margin-left input-size form-control form-control-sm" [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                    <div class = "error-content-1 extra_text" >
                      &nbsp; 
                     </div>
                    <div *ngIf="email?.invalid && (email.dirty || email.touched)" class="invalid-feedback error-content-1">
                <!-- <div *ngIf="email.errors?.['required']" >
                 
                </div>
                <div *ngIf="email.errors?.['minlength']">
                  This field must have at least 1 character.
                </div>
                <div *ngIf="email.errors?.['maxlength']">
                  This field must have at most 250 characters.
                </div>
                <div *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
                 
                </div> -->
              </div>
                </div>
             </div>

              
             <div class = "row">
                <div class = "col-sm-6">
                    <div class = "row fields-mandatory label_font">
                        *All fields are mandatory.
                      </div>
                </div>
                <div class = "col-sm-6">
                    <button type="button" class="btn btn-primary btn_color label_font margin-right send-email" (click)="scheduleBriefing()" [disabled]="disableSendEmail()" >Schedule Briefing</button>
                </div>
            
        </div>
            </div>
            </form>
        </div>
    </div>
    </div>
    </div>
    <app-footer></app-footer>

</body>
