// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authServiceURL: 'https://cpods-services-test.azurewebsites.net/auth/login',
  getUserUrl: 'https://cpods-services-test.azurewebsites.net/auth/getUser',
  getQueryResponse : 'https://rwzbpwykcf.us-east-1.awsapprunner.com/aliceChatService/getDataForQuery',
  getDashboardData:'https://cpods-services-test.azurewebsites.net/myUseCases/getDashboardDataForAlice',
  emailResults: 'https://rwzbpwykcf.us-east-1.awsapprunner.com/aliceChatService/mailSend',
  backdropLogo:
  'https://cpodsdev.blob.core.windows.net/cpods-dev-storage/cpods-ui/BGCPodsLogin.jpg',
rightLoginLogo:
  'https://cpodsdev.blob.core.windows.net/cpods-dev-storage/cpods-ui/hitachiWhite.png',
loginCpodLogo:
  'https://cpodsdev.blob.core.windows.net/cpods-dev-storage/cpods-ui/cpodLogoNew.png',

  logoDescription: 'Alice',
  leftLoginLogo: '',
  leftLoginLogoAlt: '',
  landingURL: 'https://cpod-landingpage-dot-cephalopods-hal.appspot.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
