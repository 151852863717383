import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
// import { ToasterService } from './modules/shared/common-services/toaster.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
// import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    NgbModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    DialogModule,
    ButtonModule,
    AuthModule.forRoot({
      config: {
        authority: 'https://myhitachi.okta.com/oauth2/default',
        redirectUrl: window.location.origin+"/landing_page",
        postLogoutRedirectUri: window.location.origin,
        clientId: '0oastrc9tz5CvEYmN1t7',
        scope: 'openid',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        // logLevel: LogLevel.Debug,
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // Location, {provide: LocationStrategy, useClass: PathLocationStrategy},
    DatePipe,
    // ToasterService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(){
    console.log(window.location.origin);
 }
}
