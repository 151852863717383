<body>
    <app-header></app-header>
    <div class="parent">
        <div class="container-fluid">
            <div class="container top_div">
                <div class="breadcrumbs-div">
                    <app-breadcrumbs [breadcrumbs]="this.breadcrumbs"> </app-breadcrumbs>
                </div>

            </div>
        </div>
        <div class="container details-div">
            <div class="heading-details">
                <div class="heading">Business Process Automation
                </div>
            </div>
            <div class="bottom_content">
                <div class="row">
                    <div class="col-12">
                        <h2>Deliver end-to-end process automation.</h2>
                        <p><b>Alice Dx Business Process automation</b> platform helps organizations unleash digital 
                            innovation, drive efficiency, and connect customers, employees, and systems in end-to-end
                            processes.</p>
                        <p>Business Process Automation (BPA) refers to the use of technology to streamline and 
                            automate repetitive and manual tasks within an organization's business processes.
                            It involves the application of software, robotics, artificial intelligence (AI),
                            and other digital tools to automate workflows, improve efficiency, reduce errors, 
                            and enhance overall productivity.
                        </p>
                        <p>The goal of business process automation is to eliminate time-consuming, manual tasks
                            that can be prone to human error, allowing employees to focus on more strategic and
                            value-added activities. By automating routine and repetitive tasks, organizations can
                            achieve several benefits	
                        </p>
                        <p>Hitachi America ITG partnered with Appian for process automation provides a unified 
                            and low-code design experience with enterprise-grade security, reliability, and
                            scalability for your mission-critical applications.
                        </p>
               
                    <div class="img_align">
                        <img src="../../../assets/images/business_process/BPA.png" (click)="openModal()"  class=" img_cl">
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <h2>Data Fabric</h2>
                        <img src="../../../assets/images/business_process/datafabric.png" height="auto" width="100px">
                        <p>Unify data across systems to build powerful applications.</p>
                    </div>
                    <div class="col-3">
                        <h2>Process Automation</h2>
                        <img src="../../../assets/images/business_process/process_automation.png" height="auto" width="100px">
                        <p>Automate anything with integrated workflow, RPA, AI, IDP, and API.</p>
                    </div>
                    <div class="col-3">
                        <h2>Total Experience</h2>
                        <img src="../../../assets/images/business_process/experience.png" height="auto" width="100px">
                        <p>Engage internal and external users across web and mobile experiences.</p>
                    </div>
                    <div class="col-3">
                        <h2>Process Mining</h2>
                        <img src="../../../assets/images/business_process/mining.png" height="auto" width="100px">
                        <p>Discover process inefficiencies and optimize business operations.</p>
                    </div>
                    
                </div>
                <app-modalimage  [showModal]="showModal" [modalImageUrl]="selectedImage" (close)="closeModal()"></app-modalimage>
                
                

                <div class="row key_capabilities">
                    <div class="col-12 display">
                        <img class="book-icon" src="./assets/images/Keycapabilities.svg" alt="Image 1">
                        <div class="bottom_heading"><h1>Key Capabilities</h1></div>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/business_process/Lescode.svg" class="small_icons"> Designing with low-code
                        </h2>
                        <p class="pad-l"> Quickly build intuitive user experiences with drag-and-drop, low-code design.</p>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/business_process/UserEngagement.svg" class="small_icons"> Engaging all your users
                        </h2>
                        <p class="pad-l"> Deliver a total experience that supports both external anonymous and internal users 
                        with seamless handoffs.</p>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/business_process/DStopMobile.svg" class="small_icons"> Operating across web and mobile
                        </h2>
                        <p class="pad-l"> Build applications once and operate them across the latest web browsers, mobile devices, and other digital touchpoints.</p>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/lake_house/Flexibility_Deployment.svg" class="small_icons"> Deploying with confidence
                        </h2>
                        <p class="pad-l"> Using Appian’s enterprise-grade architecture, deploy applications with high reliability, scalability, and security.</p>
                    </div>
                    <div class="col-4"></div>
                </div>

                <div class="row pad-bottom">
                <!-- <div class="col-6 align-right">
                    <div  (click)="demo()">
                        <a *ngIf="(this.demo_url != ''); else link">
                            <button type="button" class="btn btn-primary heading_font">Click here for demo</button>
                        </a>
                        <ng-template #link>
                            <button type="button" disabled class="btn btn-primary heading_font">Click here for
                                demo</button>
                        </ng-template>

                    </div>
                </div> -->
                    <div class="col-12 aligntext">
                        <button (click)="briefing()" type="button" class="btn btn-primary heading_font">
                            Schedule Briefing
                        </button>
                    </div>
                </div>


            </div>
        </div>


    </div>
    <app-footer></app-footer>
</body>
