import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedInfoService } from 'src/app/services/shared-info.service';
import { ModalService } from './modalservice.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-modalimage',
  templateUrl: './modalimage.component.html',
  styleUrls: ['./modalimage.component.scss']
})
export class ModalimageComponent implements OnInit {
  @Input() modalImageUrl:any;
  @Input() showModal:boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  private destroy$ = new Subject<void>();
  constructor(public sharedInfoService: SharedInfoService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    console.log("In child", this.modalImageUrl)
    this.modalImageUrl = this.sharedInfoService.detailPageData.image_path;
    // this.modalService.closeModal$
    // .pipe(takeUntil(this.destroy$))
    // .subscribe(() => {
    //   this.closeModal();
    // });
  }
  closeModal() {
    console.log("i am here--")
    this.showModal = false;
    console.log("pop up closeModal--",this.showModal)
    this.modalImageUrl = "";
    this.close.emit();
   // this.modalService.close();
    // this.destroy$.next();
    // this.destroy$.complete();
  }
  ngOnDestroy() {
    // this.destroy$.next();
    // this.destroy$.complete();
  }
  closePopup() {
   
  }
}
