<body>
    <app-header></app-header>
    <div class="parent">
        <div class="container-fluid">
            <div class="container top_div">
                <div class="breadcrumbs-div">
                    <app-breadcrumbs [breadcrumbs]="this.breadcrumbs"> </app-breadcrumbs>
                </div>

            </div>
        </div>
        <div class="container details-div">
            <div class="heading-details">
                <div class="heading">Lake House Data Platform</div>
            </div>
            <div class="bottom_content">
                <div class="row">
                    <div class="col-12">
                        <h2>Turn Data Chaos Into Data Clarity
                        </h2>
                        <p><b>Alice Dx Lake House Data Platform</b> normalizes, cleanses and harmonizes disparate data
                            sources, resulting in an authoritative “golden record” source of truth. No additional
                            software. No manual overhead.</p>
                        <p> <b>A Lakehouse data platform</b> is a modern data management architecture that combines the
                            best aspects of data lakes and data warehouses. It aims to address the limitations and
                            challenges associated with traditional data management approaches and provide a unified
                            solution for storing, processing, and analyzing data. It combines the flexibility of a
                            data lake with the structured query optimization capabilities of a data warehouse. It
                            offers improved data accessibility, agility, advanced analytics capabilities, data
                            governance, cost efficiency, and performance. This framework enables organizations to
                            leverage their data assets effectively, derive valuable insights, and make data-driven
                            decisions to drive business success.
                        </p>
                    </div>
                    <div class="col-12 img_align">
                        <img src="../../../assets/images/lake_house/DataLake.png" class="img_cl"  (click)="openModal('DataLake')">
                    </div>
                    
                
                    <div class="col-12">
                        <p>
                        In a Lakehouse data platform, data is stored in its raw, unprocessed form, similar to a
                        data lake. This raw data can include structured, semi-structured, and unstructured data
                        from various sources. The platform leverages scalable distributed storage systems or
                        cloud storage services, to accommodate large volumes of data.
                        </p>
                        <p>
                        However, what sets the Lakehouse data platform apart is the introduction of schema
                        enforcement and query optimization capabilities, similar to a data warehouse. While the
                        data is stored in its raw form, it is also associated with a schema or metadata that
                        provides structure and enables query optimization. This schema-on-read approach allows
                        for flexibility in data exploration and analysis, as the data can be interpreted and
                        structured at the time of analysis without requiring upfront data transformation.
                        </p>
                        <p>
                        Core capabilities can accelerate creation of curated, cleansed data in the Cloud for reporting
                        and analytics:<br>
                        <b>Ingestion: </b> Load data and manage data transformation pipelines<br>
                        <b>Data Classification:</b> Model data from source to a canonical model<br>
                        <b>Data Resolver:</b> Integrate data from across sources to generate Golden Record masters of
                        anything<br>
                        <b>Data Share:</b> Preserves privacy when sharing data
                        </p>
                    </div>
                </div>
                <div class="row key_capabilities">
                    <div class="col-1"></div>
                    <div class="col-5 pad-r">
                        <h2>Ingest Data Acquisition</h2>
                        <img src="../../../assets/images/lake_house/lake_image_1.png" (click)="openModal('lake_image_1')" class="img_middle">
                        <p>Loads data from data sources into HDFS <br>
                        Contains adaptors for most common RDBMS, Hadoop and NoSQL data sources <br>
                        Synchronizes data real-time via Change Data Capture </p>
                    </div>
                    <div class="col-5 pad-l">
                        <h2>Classify
                        Data Inventory Management
                        </h2>
                        <img src="../../../assets/images/lake_house/lake_image_2.png" (click)="openModal('lake_image_2')" class="img_middle">
                        <p>Uses machine learning to map data to business data glossary <br>
                        Manages the physical meta data inventory of data loaded <br>
                        Automatically categorizes the data into objects and attributes</p>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-1"></div>
                    <div class="col-5 pad-r">
                        <h2>Resolve Data Quality Management </h2>
                        <img src="../../../assets/images/lake_house/lake_image_3.png" (click)="openModal('lake_image_3')" class="img_middle">
                        <p>Spots & fixes data discrepancies <br>
                        De-duplicates and masters data<br>
                        Synthesizes data from multiple sources into one</p>
                    </div>
                    <div class="col-5 pad-l">
                        <h2>Share
                        Secure Data Sharing
                        </h2>
                        <img src="../../../assets/images/lake_house/lake_image_4.png" (click)="openModal('lake_image_4')" class="img_middle">
                        <p>Saves cleansed, normalized data in Fluree Core open format <br>
                        Preserves privacy when sharing data <br>
                        Enables semantic interoperability across functions and systems
                        </p>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-12">
                        <h2 class="center-align">What could your data look like? </h2>
                        <h2 class="center-align">Build Golden Records </h2>
                        <h2>Clean & Available </h2>
                        <h2>+ Data Quality</h2>
                        <p>Make your data ready for downstream enterprise consumption, sharing, and analytics. By
                        eliminating
                        data silos, your golden records are free of error and available to those that need them.
                        </p>
                        <h2>Organized & Linked</h2>
                        <h2>+ Data Interoperability</h2>
                        <p>Fluree Sense uses AI and semantics to create order and structure out of chaotic data sets,
                        providing
                        you with the ability to explore 360-degree relationships across all data assets.
                        </p>
                        <h2>Secure & Traceable</h2>
                        <h2>+ Data Governance</h2>
                        <p>Fluree Sense preserves and enforces data governance, data security, and data privacy, while also
                        simplifying data provenance and lineage. Transform your data while maintaining data quality.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 center-align ">
                        <img src="../../../assets/images/lake_house/lake_icon_1.png" class="img_wi">
                        <h2>Legacy Data Migrations </h2>
                        <p>
                        Cleanse & harmonize data from multiple sources top enable migration from legacy to modern
                        </p>
                    </div>
                    <div class="col-3 center-align ">
                        <img src="../../../assets/images/lake_house/lake_icon_2.png" class="img_wi">
                        <h2>Customer Data Integration </h2>
                        <p>
                        Easily integrate Customer, account, product and transaction data from across multiple data
                        sources
                        </p>
                    </div>
                    <div class="col-3 center-align ">
                        <img src="../../../assets/images/lake_house/lake_icon_3.png" class="img_wi">
                        <h2>Cross-Border Data Residency </h2>
                        <p>
                        Leverage secure multi-party computation capabilities to enable secure sharing of information
                        across borders.
                        </p>
                    </div>
                    <div class="col-3 center-align ">
                        <img src="../../../assets/images/lake_house/lake_icon_4.png" class="img_wi">
                        <h2>Data Consent Management </h2>
                        <p>
                        Build comprehensive and sustainable data compliance for emerging customer data privacy
                        regulations.
                        </p>
                    </div>
                    
                </div>
                <div class="key_capabilities row">
                    <div class="col-12 display">
                        <img class="book-icon" src="./assets/images/Keycapabilities.svg" alt="Image 1">
                        <h1 class="bottom_heading">Key Capabilities
                        </h1>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/lake_house/DataConnectivity.svg" class="small_icons"> Data Accessibility </h2>
                        <p class="pad-l">
                            Provides a centralized repository for storing and accessing diverse data types, making data
                            easily discoverable and accessible to different teams and departments within the organization.
                            This fosters collaboration and enables self-service analytics.
                        </p>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/intelligent_data/DataGovernance.svg" class="small_icons"> Data Governance and Compliance
                        </h2>
                        <p class="pad-l">
                            Incorporates features for data governance, data quality management, and metadata management. It
                            helps ensure data integrity, maintain compliance with data privacy regulations, and establish
                            clear data lineage, thereby mitigating risks and enhancing data governance practices.
                        </p>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/lake_house/Flexibility_Deployment.svg" class="small_icons"> Agility and Flexibility </h2>
                        <p class="pad-l">
                                Agile data exploration and analysis, as data can be transformed and structured at the time of
                                analysis. It eliminates the need for extensive data modeling upfront, enabling faster
                                time-to-insight and adaptability to changing analytical requirements.
                        </p>
                    </div>
                    <div class="col-4">
                            <h2><img src="../../../assets/images/lake_house/CostEffective.svg" class="small_icons"> Cost Efficiency
                            </h2>
                            <p class="pad-l">
                                By leveraging scalable and cost-effective storage systems and eliminating the need for extensive
                                data transformation upfront, the Lakehouse data platform offers cost savings in terms of
                                infrastructure, storage, and maintenance.
                            </p>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/lake_house/DataAnalysisInsights.svg" class="small_icons"> Advanced Analytics Capabilities</h2>
                        <p class="pad-l">
                            Supports a wide range of analytics use cases, including exploratory data analysis, machine
                            learning, and data science. It provides integration with various analytics tools and frameworks,
                            empowering organizations to gain deeper insights and drive data-driven decision-making.
                        </p>
                    </div>
                    <div class="col-4">
                        <h2><img src="../../../assets/images/lake_house/PerfomanceScalability.svg" class="small_icons"> Performance and Scalability
                        </h2>
                        <p class="pad-l">
                            The platform provides query optimization capabilities and leverages distributed processing
                            frameworks like Apache Spark or Presto to deliver fast and scalable data processing and
                            analysis. It can handle large volumes of data and scale according to the organization's needs.
                        </p>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-12" style="text-align: center;">
                        <h1>Lake House Data Framework</h1>
                        <img src="../../../assets/images/lake_house/lake_image_bottom.png" (click)="openModal('lake_image_bottom')" class="img_cl_bottom">
                    </div>
                </div>

                <div class="row pad-bottom">
                    <div class="col-12 center-align">
                        <button (click)="briefing()" type="button" class="btn btn-primary heading_font">
                        Schedule Briefing
                        </button>
                    </div>
                </div>
            </div>
            <app-modalimage  [showModal]="showModal" [modalImageUrl]="selectedImage" (close)="closeModal()"></app-modalimage>
        </div>
        
    </div>
    <app-footer></app-footer>
</body>