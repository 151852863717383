import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedInfoService } from 'src/app/services/shared-info.service';

@Component({
  selector: 'app-global-one-crm',
  templateUrl: './global-one-crm.component.html',
  styleUrls: ['./global-one-crm.component.scss']
})
export class GlobalOneCrmComponent implements OnInit {
  breadcrumbs: any
  demo_url: any;
  selectedImage:any
  showModal:any
  constructor(
    public sharedInfoService: SharedInfoService, 
    private localStorage: LocalStorageService,
    public router: Router,
    ) { }

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'Enterprise Solutions',
        url: '/enterprise-solution'
      },
      {
        label: 'Details',
        url: '/global-one-crm'
      }
    ];
    this.sharedInfoService.briefingData.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'Enterprise Solutions',
        url: '/enterprise-solution'
      },
      {
        label: 'Details',
        url: '/global-one-crm'
      },
      {
        label: 'Schedule Briefing',
        url: '/briefing'
      }
    ];
   this.demo_url = this.sharedInfoService.detailPageData.demo_url;
  }
  demo(){
    this.localStorage.store('redirectFlag', 0);
    window.open(this.sharedInfoService.detailPageData.demo_url);
  }
  briefing(){
    this.router.navigate(['/briefing']);
  }

  openModal() {
    console.log("i ma in mode")
    this.selectedImage = "../../../assets/images/global_crm_right.png";
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.selectedImage = "";  
  }
}
