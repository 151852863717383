import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { LocalStorageService } from 'ngx-webstorage';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  cpodLogo: string | undefined;
  signedInUser: string | undefined;
  rightImagePath: string | undefined;
  public isAuthenticated: boolean = false;
  public configId: any;

  constructor(private localStorage: LocalStorageService, 
    private authenticationService: AuthenticationService,
    public router: Router,
    public oidcSecurityService: OidcSecurityService,
    public loginService: LoginService) {



  }

  ngOnInit(): void {
    // this.cpodLogo = environment.loginCpodLogo;
    // this.rightImagePath = environment.rightLoginLogo;
    this.signedInUser = this.localStorage.retrieve('siggnedInUser');

    //console.log("i am here......................")
    // this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
    //   filter((s: AuthState) => !!s),
    //   map((s: AuthState) => s.isAuthenticated ?? false)
    // );
   
    //this.signedInUser = this.oidcSecurityService.getUserData();
    // console.log("Authenticated Header ----",this.isAuthenticated$);
    // this.oidcSecurityService.getUserData(this.configId).subscribe((data:any)=>{
    //   console.log("@@@@@@@@@@@@@@@@@@",data);
    // });
  }

  // logout(){
  //   console.log("i am here----")
  //   this.authenticationService.logout();  
  // }
  signOut() {
    this.localStorage.store('redirectFlag', 0);
    this.oidcSecurityService.logoff();
    //this.oidcSecurityService.logoffLocalMultiple()
    //
    // this.oidcSecurityService.logoff()
  }
  signin_page() {
    this.router.navigate(['/login']);
  }
 
}
