import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Alice DX Studio';
  constructor(private route: ActivatedRoute) {
    this.route.queryParams
      .subscribe(params => {
      //  console.log("con", params); // { orderby: "price" }

      }
      );
  }
  ngOnInit() {
    // const token = this.oidcSecurityService.getAccessToken();
    // console.log("AppComponent-token",token);
    this.route.queryParams
      .subscribe(params => {
        //console.log("AppComponent ngOnInit--", params); // { orderby: "price" }

      }
      );
  }
}
