import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ToasterService } from '../modules/shared/common-services/toaster.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private toasterService : ToasterService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // console.log("HttpInterceptor----------",request)
       // this.toasterService.showError("request");
        return next.handle(request).pipe(catchError(err => {
            console.log("Error--",err)
            if (err.url.includes('/auth/login')) 
            {  
               // console.log("Error-/auth/login-yyyyyyyyyyyy")
            }
            else{
                var message = "There was an error processing your request. Please try again later";
                this.toasterService.showError(message);
            }
            // if (err.status === 401) {
            //     // auto logout if 401 response returned from api
            //    // this.authenticationService.logout();
            //    // location.reload();
            //    //this.toasterService.showError(err.error.message);
            // }
           
            const error = err.error.message || err.message || err.statusText;
           
            return throwError(error);
        }))
    }
}