import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedInfoService } from 'src/app/services/shared-info.service';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-intelligent-data-auto',
  templateUrl: './intelligent-data-auto.component.html',
  styleUrls: ['./intelligent-data-auto.component.scss']
})
export class IntelligentDataAutoComponent implements OnInit {
  selectedImage: any;
  showModal = false;
  breadcrumbs: any
  demo_url: any;
  constructor(
    public sharedInfoService: SharedInfoService,
    private localStorage: LocalStorageService,
    public router: Router,
    public oidcSecurityService: OidcSecurityService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/intelligent-data-autom'
      }
    ];
    this.sharedInfoService.briefingData.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/intelligent-data-autom'
      },
      {
        label: 'Schedule Briefing',
        url: '/briefing'
      }
    ];
    this.demo_url = this.sharedInfoService.detailPageData.demo_url;
  }
  demo() {
    if (this.sharedInfoService.detailPageData.demo_url !== "" || this.sharedInfoService.detailPageData.demo_url == undefined) {
      console.log("Demo###", this.sharedInfoService.detailPageData.heading)
      if (this.sharedInfoService.detailPageData.heading === 'Intelligent Data Automation') {
        this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
          //console.log("i am here......................",loginResponse)
          const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
          this.loginService.isAuthenticated = isAuthenticated;
          if (isAuthenticated) {
            this.localStorage.store('redirectFlag', 0);
            window.open(this.sharedInfoService.detailPageData.demo_url);
            // this.demo_url = this.sharedInfoService.detailPageData.demo_url;
          }
          else {
            if (this.sharedInfoService.detailPageData.heading === 'Intelligent Data Automation') {
              this.localStorage.store('redirectFlag', 5);
            }

            this.router.navigate(['/login']);
          }
        });
      } else {
        //this.demo_url = this.sharedInfoService.detailPageData.demo_url;
        this.localStorage.store('redirectFlag', 0);
        window.open(this.sharedInfoService.detailPageData.demo_url);
      }
    }
  }
  briefing() {
    this.router.navigate(['/briefing']);
  }
  openModal(image_name: any) {
    console.log("i ma in mode")
    this.selectedImage = "../../../assets/images/intelligent_data/" + image_name + ".png";
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.selectedImage = "";
  }

}
