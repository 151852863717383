import {
  NgModule
} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { GenerativeAiComponent } from './modules/dx-solutions/generative-ai/generative-ai.component';
import { BriefingComponent } from './modules/shared/briefing/briefing.component';
import { GlobalOneCrmComponent } from './modules/enterprise-solution/global-one-crm/global-one-crm.component';
import { IntelligentDataAutoComponent } from './modules/dx-solutions/intelligent-data-auto/intelligent-data-auto.component';
import { LakeHouseComponent } from './modules/dx-solutions/lake-house/lake-house.component';
import { BusinessProcessComponent } from './modules/dx-solutions/business-process/business-process.component';
import { ServicenowComponent } from './modules/enterprise-solution/servicenow/servicenow.component';

const routes: Routes = [
  // {
  //   path: 'detail-view',
  //   loadChildren: () => import('./modules/detail-view/detail-view.module').then(m => m.DetailViewModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  // { path: 'login/callback', component: OktaCallbackComponent },
  {
    path: 'landing_page',
    loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'service-management',
    loadChildren: () => import('./modules/service-management/service-management.module').then(m => m.ServiceManagementModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'ot-solutions',
    loadChildren: () => import('./modules/ot-solutions/ot-solutions.module').then(m => m.OtSolutionsModule),
    //canActivate: [AuthGuard]
  },
  { path: 'global-one-crm', component: GlobalOneCrmComponent },
  
  { path: 'servicenow', component: ServicenowComponent },
  {
    path: 'enterprise-solution',
    loadChildren: () => import('./modules/enterprise-solution/enterprise-solution.module').then(m => m.EnterpriseSolutionModule),
    //canActivate: [AuthGuard]
  },
  { path: 'intelligent-data-autom', component: IntelligentDataAutoComponent },
  
  { path: 'lake-house', component: LakeHouseComponent },
  {
    path: 'dx-solutions',
    loadChildren: () => import('./modules/dx-solutions/dx-solutions.module').then(m => m.DxSolutionsModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'detail-page',
    loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule),
    //canActivate: [AuthGuard]
  },
  { path: 'generativeai', component: GenerativeAiComponent },
  { path: 'briefing', component: BriefingComponent },
  { path: 'business-process', component: BusinessProcessComponent },

  {
    path: 'briefing',
    loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule),
     //canActivate: [AuthGuard]
  },

  // {
  //   path: 'ot-solution1',
  //   loadChildren: () => import('./modules/dashboard-new/dashboard.module').then(m => m.DashboardModule),
  //    //canActivate: [AuthGuard]
  // },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  //    //canActivate: [AuthGuard]
  // },
  {
    path: '',
    redirectTo: 'landing_page',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'landing_page'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
