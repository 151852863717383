import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import {emailValidator} from '../email-validator.directive';
import { SharedInfoService } from 'src/app/services/shared-info.service';
import { ToasterService } from '../common-services/toaster.service';
import { QueryResponseService } from 'src/app/services/query-response.service';

interface PersonalDetails {
  name: string,
  lastName : string
  email: string;
}
@Component({
  selector: 'app-briefing',
  templateUrl: './briefing.component.html',
  styleUrls: ['./briefing.component.scss']
})
export class BriefingComponent implements OnInit {
  briefingPersonalInputForm !:FormGroup;
  personalInfo : PersonalDetails;
  breadcrumbs: any
  constructor(
    private router: Router,
    public sharedInfoService: SharedInfoService,
    private toasterService: ToasterService,
    private queryResponseService : QueryResponseService
  ) {
    
    this.personalInfo = {} as PersonalDetails;
   }

  ngOnInit(): void {

    this.breadcrumbs = this.sharedInfoService.briefingData.breadcrumbs;
    
    this.briefingPersonalInputForm = new FormGroup({
     name: new FormControl(this.personalInfo.name, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(250),
    ]),
    lastName: new FormControl(this.personalInfo.lastName, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(250),
    ]),
    email: new FormControl(this.personalInfo.email, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(250),
      emailValidator(),
    ]),

    
  });
  }


  get name() {
    return this.briefingPersonalInputForm.get('name')!;
  }

  get lastName() {
    return this.briefingPersonalInputForm.get('lastName')!;
  }

  get companyName() {
    return this.briefingPersonalInputForm.get('companyName')!;
  }

  get email() {
    return this.briefingPersonalInputForm.get('email')!;
  }

  disableSendEmail(){
    if(this.briefingPersonalInputForm.invalid){
         return true;
    }else{
         return false;
       }
     }

  scheduleBriefing(){
    
    let formData = {
      first_name: this.briefingPersonalInputForm.value['name'],
      last_name: this.briefingPersonalInputForm.value['lastName'],
      email:this.briefingPersonalInputForm.value['email']
    };
    this.queryResponseService.emailResults(formData).subscribe((res:any)=>{
      console.log(res);
      if(res.status_code == 200){
        this.toasterService.showSuccess(res.message);
        this.briefingPersonalInputForm.reset();
      }
      

    })
  }
}
