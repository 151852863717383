import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedInfoService } from 'src/app/services/shared-info.service';

@Component({
  selector: 'app-lake-house',
  templateUrl: './lake-house.component.html',
  styleUrls: ['./lake-house.component.scss']
})
export class LakeHouseComponent implements OnInit {
  selectedImage:any
  showModal:any
  breadcrumbs: any
  demo_url: any;
  constructor(
    public sharedInfoService: SharedInfoService,
    private localStorage: LocalStorageService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/lake-house'
      }
    ];
    this.sharedInfoService.briefingData.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/lake-house'
      },
      {
        label: 'Schedule Briefing',
        url: '/briefing'
      }
    ];
    this.demo_url = this.sharedInfoService.detailPageData.demo_url;
  }
  demo() {
    this.localStorage.store('redirectFlag', 0);
    window.open(this.sharedInfoService.detailPageData.demo_url);
  }
  briefing() {
    this.router.navigate(['/briefing']);
  }

  openModal(image_name: any) {
    console.log("i ma in mode")
    this.selectedImage = "../../../assets/images/lake_house/" + image_name + ".png";
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
    this.selectedImage = "";  
  }
}
