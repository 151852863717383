import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  // @Input()
  // breadcrumbs!: [];
  @Input() breadcrumbs!: Breadcrumb[];

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // let breadcrumb: Breadcrumb = {
    //   label: 'Home',
    //   url: ''
    // };
  }
  //this.breadcrumbs.push(breadcrumb);

}

export interface Breadcrumb {
  label: string;
  url: string;
}
