<!-- <nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="navbar-header">
      <img
        class="img cpods-logo-image"
        src="../assets/images/hitachi.png"
        allign="center"
      />

      <a class="navbar-brand text-white" href="#"
        ><span class="headingPage"
          >Vehicle Inspection Dashboard - Actionable Insights</span
        ></a 
      >
    </div>
  </div>
</nav> -->
<nav class="navbar navbar-inverse">
    <div class="container-fluid d-flex navbar-header">

      <div class="hitachi_img">
          <img
            class="img cpods-logo-image"
            src="../assets/images/hitachi.png"
            allign="center"
            
          />
    </div>
    <div class="right_text">
          <a class="navbar-brand text-aliceright" href="#"
            ><span>Alice </span>
               <span class="dxbold">DX </span> 
               <span>Studio</span
            ></a 
          >
      </div>

      <div class="user_margin" *ngIf="!(router.url == '/login'|| router.url == '/landing_page')">
        <!-- <span class="text-white"> Welcome, Username &nbsp;</span> -->
        <!-- <a href="#" (click)="logout()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            style="enable-background: new 0 0 32 32"
            xml:space="preserve"
            viewbox="0 0 32 32"
            fill="black"
          >
            <path
              d="M21.858,19.479L25.331,16H15v-1h10.327l-3.477-3.471l0.706-0.708l4.688,4.678l-4.678,4.687L21.858,19.479z M26,25.5  c0,0.276-0.224,0.5-0.5,0.5h-20C5.224,26,5,25.776,5,25.5v-20C5,5.224,5.224,5,5.5,5h20C25.776,5,26,5.224,26,5.5V10h1V5.5  C27,4.672,26.328,4,25.5,4h-20C4.671,4,4,4.672,4,5.5v20C4,26.328,4.671,27,5.5,27h20c0.828,0,1.5-0.672,1.5-1.5V21h-1V25.5z"
            ></path>
          </svg>
        </a> -->
        <!-- <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Welcome, User!
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Logout</a></li>
            </ul>
          </li>
        </ul> -->
      </div>
      
      <!-- <div class="signin_btn" *ngIf="!isAuthenticated$ && isAuthenticated$ != undefined && router.url === '/landing_page'"  (click)="signin_page()">
        <button type="button" class="signin" >Sign in</button>
       
      </div>
      <div class="signin_btn" *ngIf="isAuthenticated$ && isAuthenticated$ != undefined && router.url === '/landing_page'"  (click)="signOut()">
      <button type="button" class="signin">Sign Out</button>
      </div> -->
      <ng-container *ngIf="!this.loginService.isAuthenticated; else signout">
        <!-- <button (click)="signIn()"> Sign in </button> -->
        <div class="signin_btn" *ngIf="router.url !== '/login'" (click)="signin_page()">
        <button type="button" class="signin" >Sign in</button>
        </div>
        </ng-container>
        
        <ng-template #signout>
          <div class="signin_btn" *ngIf="router.url !== '/login'" (click)="signOut()">
            <button type="button" class="signin" >Sign Out</button>
            </div>
        </ng-template>
      <!-- <div>
        <a
          class="navbar-brand text-white d-inline-block align-text-bottom"
          href="/#/dashboard"
        >
          <img class="img cpods-logo-image" src="{{  rightImagePath }}" alt="Cpods" />
        </a>
      </div> -->
      
    
    </div>
  </nav>
  