import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private myRoute: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService
  ) {}

  saveToken(token: string): void {
    this.localStorage.store('LoggedInUser', token);
  }

  getToken() {
    return this.localStorage.retrieve('LoggedInUser');
  }

  get isLoggedInnew() {
    return this.loggedIn.asObservable();
  }

  isLoggednIn() {
    return this.getToken() !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  isForgotPassword() {
    if (document.location.href.includes('param_1')) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isResetPassword() {
    if (document.location.href.includes('param_1')) {
      return true;
    }
    return false;
  }

  logout() {
    this.localStorage.clear('LoggedInUser');
    this.localStorage.clear('siggnedInUser');
    
    this.localStorage.clear()
    this.myRoute.navigate(['/']);
  }
}
