<!-- <div class = "cpods_line">
    <div class = "d-flex justify-content-end">
        <span class ="poweredby_text">
            Powered by 
        <img src = "../../../../assets/images/CpodsLogoBlue.png" class = "cpods_logo">
    
        </span>
    </div> 
    </div> -->
    <div class = "footer_main d-flex">
        <div class = "left_text col-sm-3">
            <ul>
                <li class = "first_item">
                    <a target="_blank" [href]="termOfUse"> Terms of Use </a>
                </li>
                <li class = "px-3 first_item footer_text">
                    <a target="_blank" [href]="privacyPolicy"> Privacy Policy </a>
                </li>
            </ul>
        </div>
        <div class = "right_text col-sm-9">
        <p class = "footer_righttext">
            © Hitachi America Limited. 2022 - 2023 @ All rights reserved
        </p>
        </div>
    </div>
    