<body>
    <app-header></app-header>
    <div class="parent">
        <div class="container-fluid">
            <div class="container top_div">
                <div class="breadcrumbs-div">
                    <app-breadcrumbs [breadcrumbs]="this.breadcrumbs"> </app-breadcrumbs>
                </div>

            </div>
        </div>
        <div class="container details-div">


            <div class="heading-details">
                <div class="heading">ServiceNow</div>
            </div>

            <div class="padding">
            <div class="gx-3 grid-KPI-pane">
                <!-- <div class="column1"> -->
                    <div class="kpi-container">
                        <div class="box" (click)="openModal('ITSM')">
                          <div class="box-heading">ITSM</div>
                            <div class="card_content"><p>AD orchestration</p></div>
                            <div class="img_padding pad-top"><img src="./assets/images/servicenow/ITSM.png" class="img_box"></div>
                            <div class="arrow"><img clsss="book-icon" src="../../../assets/images/Arrow.svg" alt="Image 1"></div>
                        </div>
                      </div>
            
                      <div class="kpi-container">
                        <div class="box" (click)="openModal('ITAM')">
                          <div class="box-heading">ITAM</div>
                            <div class="card_content"><p>Software Asset Mgmt., Hardware Asset Mgmt.</p></div>
                            <div class="img_padding pad-top"><img src="./assets/images/servicenow/ITAM.png" class="img_box"></div>
                            <div class="arrow"><img clsss="book-icon" src="../../../assets/images/Arrow.svg" alt="Image 1"></div>
                        </div>
                      </div>

                      <div class="kpi-container">
                        <div class="box" (click)="openModal('ITOM')">
                          <div class="box-heading">ITOM</div>
                            <div class="card_content"><p>Discovery, Service Mapping, Agent Client Collector, Event Mgmt.</p></div>
                            <div class="img_padding"><img src="./assets/images/servicenow/ITOM.png" class="img_box"></div>
                            <div class="arrow"><img clsss="book-icon" src="../../../assets/images/Arrow.svg" alt="Image 1"></div>
                        </div>
                      </div>
            </div>
        </div>

           
            <div class="row key_capabilities">
                <div class="col-12 display">
                    <img class="book-icon" src="./assets/images/servicenow/CustomerBase.svg" alt="Image 1">
                    <h1 class="bottom_heading"> Customer Base
                    </h1>
                </div>
                <div class="col-12"><ul><li>+70K active users across Americas, Europe, and Asia regions with the majority from Asia (Japan)</li>
                <li>Module by Region:</li></ul>
                <div class="grid-KPI-pane1">
                <div class="kpi-container">
                    <!-- <img src="../../../assets/images/lake_house/lake_icon_1.png" class="img_wi"> -->
                    <h2>Hitachi America, Ltd. </h2>
                    <ul>
                        <li>Incident Management</li>
                        <li>IT Service Management</li>
                        <li>Change Management</li>
                        <li>Knowledge Management</li>
                        <li>IT Asset Management</li>
                        <li>IT Operations Management</li>
                        <li>Human Resources Service Delivery</li>
                    </ul>
                </div>
                <div class="kpi-container">
                    <!-- <img src="../../../assets/images/lake_house/lake_icon_2.png" class="img_wi"> -->
                    <h2>Hitachi Europe, Ltd. </h2>
                    <ul>
                        <li>Incident Management</li>
                        <li>IT Service Management</li>
                        <li>Change Management</li>
                        <li>Knowledge Management</li>
                        <li>IT Asset Management</li>
                    </ul>
                </div>
                <div class="kpi-container">
                    <!-- <img src="../../../assets/images/lake_house/lake_icon_3.png" class="img_wi"> -->
                    <h2>Hitachi Ltd. </h2>
                    <ul>
                        <li>Incident Management</li>
                        <li>Knowledge Management</li>
                    </ul>
                </div>
                <div class="kpi-container">
                    <!-- <img src="../../../assets/images/lake_house/lake_icon_4.png" class="img_wi"> -->
                    <h2>Hitachi Asia Ltd </h2>
                    <ul>
                        <li>Incident Management</li>
                        <li>Knowledge Management</li>
                    </ul>
                    
                </div>
                <div class="kpi-container">
                    <!-- <img src="../../../assets/images/lake_house/lake_icon_4.png" class="img_wi"> -->
                    <h2>Hitachi China Ltd. </h2>
                    <ul>
                        <li>Incident Management</li>
                    </ul>
                    
                </div>
                
                </div>
            </div>
            </div>

            <div class="row">

                <div class="col-12 display">
                    <img class="book-icon" src="./assets/images/servicenow/CustomerSuccess.svg" alt="Image 1">
                    <h1 class="bottom_heading"> Customer Success
                    </h1>
                </div>

                <div class="col-12">
                    <ul>
                        <li>Extension of IT Services for Hitachi Europe, Ltd. to provide O365 incident management support to additional group companies across Americas, Europe, and Asia regions</li>
                        <li>Integration with Hitachi America, Ltd and Hitachi Global Air Power US, LLC. Active Directory to synchronization user data with HiNext(WorkDay) </li>
                        <li>IT Service Management catalogs for IT onboarding and offboarding within Hitachi Europe, Ltd group companies</li>
                    </ul>
                </div>

                
            </div>
            <div class="key_capabilities row">
                <div class="col-12 display">
                    <img class="book-icon" src="./assets/images/Keycapabilities.svg" alt="Image 1">
                    <h1 class="bottom_heading"> Product Key Capabilities
                    </h1>
                </div>
                <div class="col-12">
                    <ul>
                        <li>
                            Incident Management, IT Service Management, Change Management, Knowledge Management, IT Asset Management, IT Operations Management, HR Service Delivery
                        </li>
                        <li>
                            Multi-language support for choice selection, notifications, menus
                        </li>
                        <li>
                            Auto-assignment of tickets to supporting teams for Incident Management, IT Service Management and HR Service Delivery
                        </li>
                            <li>
                                Integration with Microsoft SCCM, Insight (hardware asset procurement)
                            </li>
                        <li>
                            Virtual Agent for IT Incident Management and HR Service Delivery using Natural Language Understanding and pre-built conversational topics
                        </li>
                        <li>
                            Metric and analytical-based reporting
                        </li>
                    </ul>
                </div>
            </div>


            <div class="row">
                <div class="col-12 align-center">
                    <button (click)="briefing()" type="button" class="btn btn-primary heading_font">
                        Schedule Briefing
                    </button>
                </div>
                
            </div>
            <app-modalimage  [showModal]="showModal" [modalImageUrl]="selectedImage" (close)="closeModal()"></app-modalimage>

        </div>
      
    </div>
    <app-footer></app-footer>
</body>