<body>
    <app-header></app-header>
    <div class="parent">
        <div class="container-fluid">
            <div class="container top_div">
                <div class="breadcrumbs-div">
                    <app-breadcrumbs [breadcrumbs]="this.breadcrumbs"> </app-breadcrumbs>
                </div>

            </div>
        </div>
        <div class="container details-div">


            <div class="heading-details">
                <div class="heading">One Hitachi’ CRM <i>(Enabling a customer-centric organization with a 360° customer view)</i></div>
            </div>
            <div class="row">
                <div class="col-9">
                    <div class="row">
                        <div class="col-12">
                            <!-- <h3>Enabling a customer-centric organization with a 360° customer view. </h3> -->
                            Realize Hitachi’s sustainable growth ambitions in Green, Digital & Innovation by combining
                            IT & OT & Products and selling solutions . Provide complete and accurate data for managing
                            the sales pipeline, facilitate cross/up-selling and increasing profitable orders

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h1>Benefits of Global One CRM</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <h2>Gather and share group-wide marketing information </h2>
                            <img src="../../../assets/images/global_crm/gc1.png" class="img_middle">
                            <p> Visualize white space, competitors, and macro trends through info-sharing across Hitachi
                                Group</p>


                        </div>
                        <div class="col-4">
                            <h2>Provide insights with data analytics </h2>
                            <img src="../../../assets/images/global_crm/gc2.png" class="img_middle">
                            <p> Leverage aggregated data through data analytics for strategic planning and marketing.
                            </p>


                        </div>
                        <div class="col-4">
                            <h2>Enhance sales activities with corporate intelligence </h2>
                            <img src="../../../assets/images/global_crm/gc3.png" class="img_middle">
                            <p> Prioritize opportunities and visualize sales progress for expanding cross-sales and
                                creating SIB opportunities among BUs. </p>


                        </div>
                    </div>

                </div>
                <div class="col-3">
                    <img src="../../../assets/images/global_crm/global_crm_right.png" class="img_cl"  (click)="openModal()">
                </div>
                <app-modalimage  [showModal]="showModal" [modalImageUrl]="selectedImage" (close)="closeModal()"></app-modalimage>
            </div>
            <div class="row">
                <div class="col-6 align-right">
                    <button (click)="briefing()" type="button" class="btn btn-primary heading_font">
                        Schedule Briefing
                    </button>
                </div>
                <div class="col-6">

                </div>
            </div>



        </div>


    </div>
    <app-footer></app-footer>
</body>