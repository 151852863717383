<body>
    <app-header></app-header>
    <div class="parent">
        <div class="container-fluid">
            <div class="container top_div">
                <div class="breadcrumbs-div">
                    <app-breadcrumbs [breadcrumbs]="this.breadcrumbs"> </app-breadcrumbs>
                </div>

            </div>
        </div>
        <div class="container details-div">
            <div class="heading-details">
                <div class="heading">AI generative</div>
            </div>
            <div class="bottom_content">
                <div class="row">
                    <div class="col-12">
                        <b>Alice Dx Generative AI</b> Service transforms the way enterprise users interact with their data
                        and applications. It uniquely combines the latest innovations in natural language
                        understanding (NLP), generative AI, retrieval AI models, and reinforcement learning with
                        Hitachi AI Innovation and model driven architecture.
                    </div>
                    <div class="col-12 img_align">
                        <img src="../../../assets/images/generative_ai/GenerativeAI.png" (click)="openModal()"  class="img_cl">
                    </div>
                    
                </div>
                <div class="row key_capabilities">
                    <div class="col-12 display">
                        <img class="book-icon" src="./assets/images/Keycapabilities.svg" alt="Image 1">
                        <h1 class="bottom_heading">Key Capabilities
                        </h1>
                    </div>
                    <div class="col-4">
                        <h5><img src="../../../assets/images/generative_ai/userIntuitive.svg" class="small_icons"> User Intuitive</h5>
                        <p class="pad-l">Simple Natural language interface allowing users to access, locate and retrieve information
                        across enterprise systems.</p>
                    </div>
                    <div class="col-4">
                        <h5><img src="../../../assets/images/generative_ai/growthInsights.svg" class="small_icons"> Actionable Insights </h5>
                        <p class="pad-l">Outputs presented in relevant user personas from the underlying source applications across
                        enterprise systems.</p>
                    </div>
                    <div class="col-4">
                        <h5><img src="../../../assets/images/generative_ai/DataGovernance.svg" class="small_icons"> Data Governance</h5>
                        <p class="pad-l">Via Data Hyperautomation, Graph and Identity Access Management roles and privileges</p>
                    </div>
                    <div class="col-4">
                        <h5><img src="../../../assets/images/generative_ai/dashboard.svg" class="small_icons"> Dashboard and Charts</h5>
                        <p class="pad-l">Dashboard to access the most relevant information</p>
                    </div>
                    <div class="col-4">
                        <h5><img src="../../../assets/images/generative_ai/ComprehensiveDataSecurity.svg" class="small_icons"> Comprehensive Data Security</h5>
                        <p class="pad-l">Pre-integrated HAL Infosec layer and Data Graph Hyperautomation layer protects Hitachi’s
                        Intellectuals without exposing sensitive data to the external cloud environment.</p>
                    </div>
                    <div class="col-4">
                        <h5><img src="../../../assets/images/lake_house/Flexibility_Deployment.svg" class="small_icons"> Ease of Deployment</h5>
                        <p class="pad-l">Across cloud-native, on-premise, Hybrid model (air gapped)</p>
                    </div>
                </div>
                <div class="row pad-bottom">
                    <div class="col-12 padding">
                        <h4>Start using the Alice Dx Generative AI services </h4>
                    </div>
                    <div class="col-6 align-right">
                        <div  (click)="demo()">
                            <a *ngIf="(this.demo_url != ''); else link">
                                <button type="button" class="btn btn-primary heading_font">Click here for demo</button>
                            </a>
                            <ng-template #link>
                                <button type="button" disabled class="btn btn-primary heading_font">Click here for
                                    demo</button>
                            </ng-template>
    
                        </div>
                    </div>
                    <div class="col-6">
                        <button (click)="briefing()" type="button" class="btn btn-primary heading_font">
                            Schedule Briefing
                        </button>
                    </div>
                </div>
    
            </div>
            <app-modalimage  [showModal]="showModal" [modalImageUrl]="selectedImage" (close)="closeModal()"></app-modalimage>



        </div>


    </div>
    <app-footer></app-footer>
</body>