import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class QueryResponseService {
 headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': '*',
    'Authorization':'Bearer 7198ddc7e8c353272793812f2352b7b33b9c79ea'
  });
  options = {
    headers: this.headers,
  };
  public tabData: any;
  constructor(private authService: AuthenticationService,
    private httpClient: HttpClient) { }

    
  getQueryResponse(input: any) : Observable <any> {
    return this.httpClient.post < any > (
      environment.getQueryResponse,
      input,
      this.options
    )
  }
  getDashboardData() : Observable <any> {
    return this.httpClient.get < any > (
      environment.getDashboardData,
      this.options
    )
  }

  emailResults(input: any) : Observable <any> {
    return this.httpClient.post < any > (
      environment.emailResults,
      input,
      this.options
    )
  }
}
