import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedInfoService } from 'src/app/services/shared-info.service';

@Component({
  selector: 'app-business-process',
  templateUrl: './business-process.component.html',
  styleUrls: ['./business-process.component.scss']
})
export class BusinessProcessComponent implements OnInit {
  selectedImage:any
  showModal:any
  breadcrumbs: any
  demo_url: any;
  imgSrc:any;
  constructor(public sharedInfoService: SharedInfoService,
    public oidcSecurityService: OidcSecurityService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private loginService: LoginService,
    public router: Router,
    private localStorage: LocalStorageService,) { }

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/business-process'
      }
    ];
    this.sharedInfoService.briefingData.breadcrumbs = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'DX Solutions',
        url: '/dx-solutions'
      },
      {
        label: 'Details',
        url: '/business-process'
      },
      {
        label: 'Schedule Briefing',
        url: '/briefing'
      }
    ];
  }

  demo() {
    if (this.sharedInfoService.detailPageData.demo_url !== "" || this.sharedInfoService.detailPageData.demo_url == undefined) {
      console.log("Demo###", this.sharedInfoService.detailPageData.heading)
      if (this.sharedInfoService.detailPageData.heading === 'Generative AI Services') {
        this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
          //console.log("i am here......................",loginResponse)
          const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
          this.loginService.isAuthenticated = isAuthenticated;
          if (isAuthenticated) {
            this.localStorage.store('redirectFlag', 0);
            window.open(this.sharedInfoService.detailPageData.demo_url);
            // this.demo_url = this.sharedInfoService.detailPageData.demo_url;
          }
          else {
            if (this.sharedInfoService.detailPageData.heading === 'Generative AI Services') {
              this.localStorage.store('redirectFlag', 2);
            }
            else if (this.sharedInfoService.detailPageData.heading === 'HR Service Delivery') {
              this.localStorage.store('redirectFlag', 4);
            }
            this.router.navigate(['/login']);
          }
        });
      } else {
        //this.demo_url = this.sharedInfoService.detailPageData.demo_url;
        this.localStorage.store('redirectFlag', 0);
        window.open(this.sharedInfoService.detailPageData.demo_url);
      }
    }
  }
  briefing() {
    this.router.navigate(['/briefing']);
  }

  
  openModal() {
    //console.log("openModal--",this.showModal)
    this.selectedImage = '../../../assets/images/BPA.png';
    this.showModal = true;
  }

  closeModal() {
   // console.log("closeModal--",this.showModal)
    this.showModal = false;
    this.selectedImage = ""
   // console.log("closeModal--",this.showModal)
    //this.destroy$.next();
    //this.destroy$.complete();
  }
  ngOnDestroy() {
    // this.destroy$.next();
    // this.destroy$.complete();
  }
}
